

const Widget = ({ data, children, showTitle = false }) => {

    return <div className="flex flex-row w-full h-full max-h-[10rem] p-3 rounded-sm bg-primary overflow-auto">
        <div className="p-1">{data.icon}</div>
        <div className="flex-grow flex flex-col w-full h-full overflow-auto">
            <div className="flex-grow flex px-2 text-sm">
                {showTitle && <span className="flex-grow p-2">{data.title}</span>}
                <span>{data.text}</span>
            </div>
            {children}
        </div>
    </div>
}

export default Widget