
const SingleCard = ({ title, options, value }) => {

    return (
        <div
            className={ `flex-1 w-full p-4 text-white-gray` }>
            <div className={ `flex flex-col p-4 border-[1px] border-b-[5px] border-r-[3px] border-gray-light ${options?.bottomColor} rounded-xl` }>
                <div className="min-h-[10rem] flex justify-center items-center">
                    <span className="text-[5rem]">{ value }</span>
                </div>
                <span className="font-bold text-xl flex">{ title }</span>
            </div>
        </div>
    )
}

export default SingleCard