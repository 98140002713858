import { useRef, useState } from "react"
import { useOutsideFinder } from "../../hooks/useOutsideFinder"
import OrderOptions from "./OrderOptions"
import Drivers from "../Drivers"
import OrderDetails from "../OrderDetails"
import { formatDistanceToNow, parseISO } from "date-fns"
import { formatDateTime } from "../../hooks/ConvertTime"
import { BiWorld } from "react-icons/bi";
import { GoCopy } from "react-icons/go";
import OrderPhotos from "../OrderPhotos"
import OrderStatus from "../../utils/OrderStatus"

const Order = ({ row }) => {
    const [show, setShow] = useState(false)
    const [showAssign, setShowAssign] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const [showPhotos, setShowPhotos] = useState(false)
    const optionRef = useRef()

    useOutsideFinder(() => setShow(false), optionRef)

    const onPreviewPhoto = () => {
        setShowPhotos(true)
    }

    const getColorByStatusId = (statusId, row) => {
        const status = OrderStatus.find(obj => obj.status_id === statusId);

        // Ensure that status is defined before attempting to access its color property
        const currentColor = status ? status.color : 'bg-gray-light';

        // Log the color (if it exists) or a message indicating no status was found
        console.log(currentColor ? currentColor : 'Status not found', statusId);

        return <span className={ `p-1 px-2 rounded-md w-fit italic text-primary ${currentColor}` }>{ row.status_name || '' }</span>;
    };


    return (
        <div className="min-w-[20rem] w-full md:max-w-[20rem] p-4">
            <div className={ `relative flex flex-col p-3 bg-secondary border border-transparent shadow-gray-light shadow-sm rounded-md w-full text-sm` }>
                { showAssign && <Drivers order={ row } setShowAssign={ () => setShowAssign(false) } orderId={ row.id } /> }
                { showDetails && <OrderDetails order={ row } setShowDetails={ () => setShowDetails(false) } /> }
                <OrderPhotos show={ showPhotos } order={ row } onClick={ () => setShowPhotos(false) } />
                <div className="absolute bottom-2 right-2" onClick={ () => setShow(true) } onMouseEnter={ () => setShow(true) } onMouseLeave={ () => setShow(false) }>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={ 1.5 } stroke="currentColor" className="w-6 h-6 text-white-gray hover:text-white cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                    </svg>
                    <OrderOptions order={ row } show={ show } optionRef={ optionRef } setShowAssign={ setShowAssign } setShowDetails={ setShowDetails } onPreviewPhoto={ onPreviewPhoto } />
                </div>
                <div className="p-2 w-full flex items-center justify-between">
                    <span className="font-bold text-[18px]">#{ row.id }</span>
                    <span className="italic">{ formatDistanceToNow(parseISO(row?.created_at)) }</span>
                </div>
                <div className="p-2 w-full flex items-center justify-between">
                    {
                        row.is_confirmed === 0 || row.is_confirmed === '0'
                            ? <span className="p-1 rounded-md text-red w-fit italic">Need confirmation</span>
                            : <span className="p-1 rounded-md text-green w-fit italic">Confirmed</span>
                    }
                    <>{ getColorByStatusId(row.status_id, row) }</>
                </div>
                <div className="flex items-center p-1">
                    <span className="px-1 text-white">Last Update:</span>
                    <span className="px-1">{ formatDateTime(row.updated_at, 'hh:mm aa') }</span>
                </div>
                <div className="flex items-center p-1">
                    <span className="px-1 text-white">Driver:</span>
                    <span className="px-1">{ row.first_name || '' } { row.last_name || '' }</span>
                </div>
                <div className="flex items-center p-1">
                    <span className="px-1 text-white">Store:</span>
                    <span className="px-1">{ row.store_name }</span>
                </div>
                <div className="flex flex-col border-t border-gray-light p-1">
                    <div className="flex items-center p-1 font-bold cursor-pointer hover:opacity-50" title={ row.delivery_location || 'No Location Assigned' } onClick={ () => navigator.clipboard.writeText(row.delivery_location || '') }>
                        <span className="flex-grow px-1">Customer Details</span>
                        <GoCopy />
                    </div>

                    <div className="flex items-center">
                        <span className="p-1 text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={ 1.5 } stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                            </svg>
                        </span>
                        <span className="px-1">{ row.customer_first_name } { row.customer_last_name }</span>
                    </div>
                    <div className="flex items-center">
                        <span className="p-1 text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={ 1.5 } stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                            </svg>
                        </span>
                        <span className="px-1">{ row.customer_number }</span>
                    </div>
                </div>
                { parseInt(row.created_from_web) === 1 && <BiWorld size={ 25 } className="absolute left-1/2 -translate-x-1/2 text-blue-600" /> }
            </div>
        </div>
    )
}

export default Order