import BackButton from "./BackButton"

export const Container = ({ children, className }) => {
    return (
        <div className={"flex flex-col bg-primary w-full text-color5 h-[100%]  p-5 shadow-md -z-1 " + className}>
            {children}
        </div>
    )
}

export const CentralContainer = ({ children, className }) => {
    return (
        <div className={"flex flex-col bg-primary w-full md:min-w-[40rem] md:max-w-[40rem] text-color5 h-full p-5 shadow-md -z-1 " + className}>
            {children}
        </div>
    )
}

export const ContainerHeader = ({ username, page, showBack = false, item }) => {

    return (
        <div
            className="flex items-center italic w-full p-4">
            {
                !showBack
                    ? null
                    : <BackButton />
            }
            <span>{page} {username ? '- ' + username : null} {item ? '- ' + item : null}</span>
        </div>
    )
}

export const ContainerFooter = ({ text, onClick, className }) => {
    return (
        <div className={`flex w-full justify-end p-2 my-2 bg-primary ${className}`}>
            <button className={`p-3 md:w-28 w-full bg-plant font-bold text-primary rounded`} onClick={onClick}>{text}</button>
        </div>
    )
}