import SingleCard from "./SingleCard"


const Card = ({ data }) => {
    const totalOrders = data?.length
    const totalPending = data?.filter(d => { return d.is_confirmed === 0 || d.is_confirmed === '0' }).length
    const totalConfirmed = data?.filter(d => { return d.is_confirmed === 1 || d.is_confirmed === '1' }).length
    const totalConmpleted = data?.filter(d => { return d.status_id === 2 }).length

    return (

        <div className="w-full grid grid-cols-6 sm:grid-cols-4 xs:grid-cols-2">
            <SingleCard title={ 'Last Orders' } value={ totalOrders } options={ { bottomColor: 'border-plant' } } />
            <SingleCard title={ 'Pending' } value={ totalPending } options={ { bottomColor: 'border-red' } } />
            <SingleCard title={ 'Confirmed' } value={ totalConfirmed } options={ { bottomColor: 'border-color4' } } />
            <SingleCard title={ 'Completed' } value={ totalConmpleted } options={ { bottomColor: 'border-green' } } />
        </div>
    )
}

export default Card