import { isMobile } from "react-device-detect"
import MenuDesk from './MenuDesk'
import MenuMobile from './MenuMobile'

const LeftSide = ({ user, active }) => {
    return (
        !isMobile
            ? <MenuDesk user={user} active={active} />
            : <MenuMobile user={user} active={active} />
    )
}

export default LeftSide