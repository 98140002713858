import useData from "../../hooks/useData"
import HttpStatus from "../../utils/HttpStatus"

const Driver = ({ data, orderId, setShowAssign }) => {
    const { actionOrder, setRefreshOrders } = useData()

    const handleAssign = async () => {
        if (!data.id)
            return

        const response = await actionOrder('ASSIGN_ORDER', { order_id: orderId, driver_id: data.id })
        if (response.status === HttpStatus.OK) {
            setRefreshOrders(true)
            setShowAssign(false)
        }
    }
    return (
        <div className="flex flex-row whitespace-nowrap cursor-pointer p-2 hover:bg-color4 hover:text-primary" onClick={handleAssign}>
            {data.first_name} {data.last_name}
        </div>
    )
}

export default Driver