

const MiniLoader = ({ show }) => {

    return (show && <div className="absolute bg-primary w-full h-full p-3 rounded-sm opacity-80 z-[199]">
        <div className="flex items-center justify-center w-full h-full">
            <div className="loader m-auto">
            </div>
        </div>
    </div>)
}

export default MiniLoader