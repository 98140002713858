import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { DataProvider } from "../context/DataProvider";
import Authenticator from "./Authenticator";
import Login from "../pages/Login";
import Home from "../pages/Home";
import OrderList from "../pages/OrderList";
import OrdersPending from "../conponents/OrdersPending";


export default function Schema() {

    return (
        <BrowserRouter>
            <DataProvider>
                <Routes>
                    <Route exact path='/login' element={ <Login /> } />
                </Routes>
                <Routes>
                    <Route element={ <Authenticator /> }>
                        <Route exact path='/' element={ <Home /> } />
                        <Route exact path='/home' element={ <Home /> } />
                        <Route exact path='/order_list' element={ <OrderList /> } />
                        <Route exact path='/pending' element={ <OrdersPending /> } />
                    </Route>
                    <Route path='*' element={ <Navigate to='/login' replace /> } />
                </Routes>
            </DataProvider>
        </BrowserRouter>
    )
}