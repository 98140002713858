import { useEffect } from "react";
import useData from "../../hooks/useData"
import PlaySoundOnShow from './PlaySound'
import { FaTruckLoading } from "react-icons/fa";


const NewOrderLoader = () => {
    const { showOrderLoader, setShowOrderLoader } = useData()

    const hide = () => {
        setTimeout(() => setShowOrderLoader(false), 2400)
    }

    useEffect(() => {
        hide()
    }, [])

    return (
        showOrderLoader && <div className="fixed w-96 h-24 m-2 top-0 right-0 bg-green rounded-md shadow-sm shadow-yellow-700 z-[202]">
            <PlaySoundOnShow play={ showOrderLoader } />
            <div className="relative flex flex-row items-center h-full px-2 gap-4">
                <FaTruckLoading size={ 60 } color="#ffffff" />
                <div className="flex-grow text-md italic pb-3">You have a new order received!</div>
                <button className="absolute top-2 right-2 px-2 text-primary rounded-sm" onClick={ hide }>X</button>
            </div>
        </div>
    )
}

export default NewOrderLoader