function formatNumber(number, decimalDigits = 2) {
    if (!number)
        return 'NaN'
    return parseFloat(number).toLocaleString(undefined, {
        minimumFractionDigits: decimalDigits,
        maximumFractionDigits: decimalDigits,
    });
}

export default formatNumber
