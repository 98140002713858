import { useEffect, useState } from 'react'
import Card from '../conponents/Card'
import { Container, ContainerHeader } from '../conponents/Containers'
import MainLayout from '../conponents/Layout'
import useData from '../hooks/useData'
import HttpStatus from '../utils/HttpStatus'

export default function Home() {
    const { actionOrder } = useData()
    const [orderList, setOrderList] = useState([])

    const getOrders = async () => {
        try {
            const response = await actionOrder('GET')
            if (response.status !== HttpStatus.OK) {
            } else {
                if (response.status === HttpStatus.OK) {
                    if (response.data.length > 0) {
                        setOrderList(response.data)
                    }
                }
            }
        } catch (err) {
        }
    }

    useEffect(() => {
        getOrders()
        // eslint-disable-next-line
    }, [])

    return (
        <MainLayout>
            <Container>
                <ContainerHeader showBack={ true } page={ 'Home' } />
                <Card data={ orderList } />
            </Container>
        </MainLayout>
    )
}