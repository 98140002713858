import { createContext, useEffect, useState } from "react";
import { Spalsh } from "../conponents/Splash";
import { checkTokenExpiration } from "../hooks/useAxios";
import { useTheme } from "../hooks/useTheme";
import { useFetch } from "../hooks/useFetch";
import HttpStatus from "../utils/HttpStatus";
import NewOrderLoader from "../conponents/Global/NewOrderLoader";

const DataContext = createContext({})

export const DataProvider = ({ children }) => {
    const { fetchData } = useFetch()
    const current_theme = useTheme()
    const [showSpalsh, setShowSplash] = useState(true)
    const [showMenu, setShowMenu] = useState(true)
    const [refreshOrders, setRefreshOrders] = useState(true)
    const [showLoader, setShowLoader] = useState(true)
    const [showOrderLoader, setShowOrderLoader] = useState(false)

    const hideSplash = (time = 1500) => {
        setTimeout(() => setShowSplash(false), [time])
    }

    const actionUser = async (action, payload) => {
        switch (action) {
            case 'LOGIN': {
                try {
                    const response = await fetchData('POST', 'user/login', payload)
                    if (response instanceof Error) {
                        return { status: 500, err: '500 - Internal Error.' }
                    } else {
                        if (response.status === HttpStatus.OK && response.data.data.length > 0) {
                            return { status: HttpStatus.OK, data: response.data.data }
                        } else
                            return { status: HttpStatus.CREATED, err: 'no data to show' }
                    }
                } catch (err) {
                    return { status: err.status, err: err.message }
                }
            }
            default: return
        }
    }

    const actionOrder = async (action, payload) => {
        switch (action) {
            case 'GET': {
                const response = await fetchData('POST', 'order/get', payload)
                if (response instanceof Error) {
                    return { status: 500, err: '500 - Internal Error.' }
                } else {
                    if (response.status === HttpStatus.OK && response.data.result.length > 0) {
                        return { status: HttpStatus.OK, data: response.data.result || [] }
                    } else
                        return { status: HttpStatus.CREATED, err: 'no data to show' }
                }
            }
            case 'CONFIRM_ORDER': {
                const response = await fetchData('POST', 'order/confirm', { id: payload.id })
                if (response instanceof Error) {
                    return { status: 500, err: '500 - Internal Error.' }
                } else {
                    if (response.status === HttpStatus.OK) {
                        return { status: HttpStatus.OK, data: response.data.data }
                    } else
                        return { status: HttpStatus.CONTINUE, err: `Order ${payload.id} is not valid.` }
                }
            }
            case 'ASSIGN_ORDER': {
                const response = await fetchData('POST', 'order/assign', { order_id: payload.order_id, driver_id: payload.driver_id })
                if (response instanceof Error) {
                    return { status: 500, err: '500 - Internal Error.' }
                } else {
                    if (response.status === HttpStatus.OK) {
                        return { status: HttpStatus.OK, data: response.data.data }
                    } else
                        return { status: HttpStatus.CONTINUE, err: `Assign driver to order #${payload.order_id} failed.` }
                }
            }
            case 'ORDER_DETAILS': {
                const response = await fetchData('POST', 'order/details', { order_id: payload.order_id })
                if (response instanceof Error) {
                    return { status: 500, err: '500 - Internal Error.' }
                } else {
                    if (response.status === HttpStatus.OK) {
                        return { status: HttpStatus.OK, data: response.data.data, orderBill: response.data.order_bill }
                    } else
                        return { status: HttpStatus.CONTINUE, err: `Assign driver to order #${payload.order_id} failed.` }
                }
            }
            case 'PICK_TIME': {
                const response = await fetchData('POST', 'order/picktime')
                if (response instanceof Error) {
                    return { status: 500, err: '500 - Internal Error.' }
                } else {
                    if (response.status === HttpStatus.OK) {
                        return { status: HttpStatus.OK, data: response.data.data }
                    } else
                        return { status: HttpStatus.CONTINUE, err: `Assign driver to order #${payload.order_id} failed.` }
                }
            }
            default: return
        }
    }

    const actionDriver = async (action, payload) => {
        const config = checkTokenExpiration()
        switch (action) {
            case 'GET': {
                const response = await fetchData('GET', 'driver/get', {}, config)
                if (response instanceof Error) {
                    return { status: 500, err: '500 - Internal Error.' }
                } else {
                    if (response.status === HttpStatus.OK && response.data.data.length > 0) {
                        return { status: HttpStatus.OK, data: response.data.data }
                    } else
                        return { status: HttpStatus.CREATED, err: 'no data to show' }
                }
            }
            default: return
        }
    }

    useEffect(() => {
        hideSplash()
        // eslint-disable-next-line
    }, [])

    const values = {
        showSpalsh, setShowSplash, hideSplash,
        showMenu, setShowMenu,
        actionUser,
        actionOrder, refreshOrders, setRefreshOrders,
        actionDriver,
        showLoader, setShowLoader,
        showOrderLoader, setShowOrderLoader,
    }
    return (
        <div className={ `${current_theme}` }>
            <DataContext.Provider value={ values }>
                {/* <SocketClient /> */ }
                { showSpalsh ? <Spalsh /> : children }
                <NewOrderLoader />
            </DataContext.Provider>
        </div>
    )
};

export default DataContext