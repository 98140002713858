const OrderStatus = [
    {
        status_id: 1,
        name: "Pending",
        color: 'bg-color1'
    },
    {
        status_id: 2,
        name: "Complete",
        color: 'bg-green'
    },
    {
        status_id: 3,
        name: "Reject",
        color: 'bg-red'
    },
    {
        status_id: 4,
        name: "Confirmed",
        color: 'bg-color4'
    },
    {
        status_id: 5,
        name: "Getting Order",
        color: 'bg-color5'
    },
    {
        status_id: 6,
        name: "On The Way",
        color: 'bg-color6'
    },
    {
        status_id: 7,
        name: "Order Picked",
        color: 'bg-color7'
    },
    {
        status_id: 8,
        name: "Scheduled",
        color: 'bg-gray-light'
    },
    {
        status_id: 9,
        name: "Canceled",
        color: 'bg-gray-light'
    }
]

export default OrderStatus