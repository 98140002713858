import { Container, ContainerHeader } from "../conponents/Containers"
import MainLayout from "../conponents/Layout"
import Orders from "../conponents/Orders"


const OrderList = () => {

    return <MainLayout>
        <Container>
            <ContainerHeader showBack={ true } page={ 'Order List' } />
            <Orders />
        </Container>
    </MainLayout>
}
export default OrderList