import { isMobile } from "react-device-detect"

const MenuItem = ({ children, onClick, className, ico }) => {
    return (
        <div
            className={"flex w-full py-3 px-1 text-color5 hover:bg-plant hover:text-primary cursor-pointer border-b border-primary " + className}
            onClick={onClick}>
            <span className="px-1">{ico && ico}</span>
            <span className={"px-1 md:block " + (!isMobile && 'hidden')}>{children}</span>
        </div>
    )
}

export default MenuItem