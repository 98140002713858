import axios from "axios"
import { jwtDecode } from 'jwt-decode'

const refresh = async () => {
    try {
        const response = await axios.post(`/api/user/refresh`, {
            refreshToken: sessionStorage.getItem('refresh'),
        })
        const { status, accessToken, refreshToken } = response.data
        if (status) {
            sessionStorage.setItem('ruser', accessToken)
            sessionStorage.setItem('refresh', refreshToken)
        }
    } catch (error) {
        console.error('Token refresh failed:', error)
    }
};

export const checkTokenExpiration = async (isImage = false, onUploadProgress) => {
    if (sessionStorage.getItem('ruser')) {
        const currentTime = new Date().getTime()
        const decode = sessionStorage.getItem('ruser') && jwtDecode(sessionStorage.getItem('ruser'))
        if (decode.exp * 1000 < currentTime) {
            await refresh()
        }
        return !isImage ? { headers: { authorization: `Bearer ${sessionStorage.getItem('ruser')}` } } : { headers: { authorization: `Bearer ${sessionStorage.getItem('ruser')}`, "Content-Type": "multipart/form-data", onUploadProgress } }
    }
    return
};