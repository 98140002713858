import { useState } from "react"
import useData from "../hooks/useData"

const ChangePassword = ({ show, setShow }) => {
    const [oldPass, setOldPass] = useState('')
    const [newPass, setNewPass] = useState('')
    const [confirmPass, setConfirmPass] = useState('')
    const { usersAction } = useData()

    const changePassword = () => {
        if (!oldPass)
            return
        if (!newPass)
            return
        if (!confirmPass)
            return

        if (newPass !== confirmPass)
            return

        usersAction('CHANGE_PASS', { oldPass, newPass })
            .then()
    }

    return (
        show
            ? <div className="fixed inset-0">
                <div className="w-full h-full flex flex-col items-center justify-center bg-transparent">
                    <div className="flex flex-col max-w-3xl max-h-3xl bg-secondary p-10 text-white-gray">
                        <div className="flex w-full text-2xl text-white-gray border-b p-1 mb-5">Change Password</div>
                        <div className="flex items-center py-1">
                            <label className="px-1 flex-1 ">Old Password</label>
                            <input type="password" className="flex-1 outline-none bg-primary p-2" value={ oldPass } onChange={ (e) => setOldPass(e.target.value) } />
                        </div>
                        <div className="flex items-center py-1">
                            <label className="px-1 flex-1 ">New Password</label>
                            <input type="password" className="flex-1 outline-none bg-primary p-2" value={ newPass } onChange={ (e) => setNewPass(e.target.value) } />
                        </div>
                        <div className="flex items-center py-1">
                            <label className="px-1 flex-1 ">Confirm Password</label>
                            <input type="password" className="flex-1 outline-none bg-primary p-2" value={ confirmPass } onChange={ (e) => setConfirmPass(e.target.value) } />
                        </div>
                        <div className="flex justify-end pt-5">
                            <button className="bg-plant p-2 px-4 font-bold text-primary" onClick={ changePassword }>Save</button>
                            <button className="bg-secondary p-2 px-4 font-bold text-primary" onClick={ () => setShow(false) }>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            : null
    )
}

export default ChangePassword