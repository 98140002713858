import OptionButton from "../Button/OptionButton"
import useData from "../../hooks/useData"
import HttpStatus from "../../utils/HttpStatus"
import { useFetch } from "../../hooks/useFetch"

const OrderOptions = ({ show, optionRef, order, setShowAssign, setShowDetails, onPreviewPhoto }) => {
    const { actionOrder, setRefreshOrders } = useData()
    const { fetchData, loading } = useFetch()

    const handleConfirm = async () => {
        const response = await actionOrder('CONFIRM_ORDER', { id: order.id })
        if (response.status === HttpStatus.OK) {
            setRefreshOrders(true)
        } else {

        }
    }

    const hadnleChangeStatus = async () => {
        try {
            const response = await fetchData('POST', 'order/reject', { id: order.id })
        } catch (err) {

        }
    }

    const handleAssign = async () => {
        setShowAssign(c => true)
    }

    return (
        show
            ? <div className="absolute w-fit whitespace-nowrap right-0 bg-primary z-[100]">
                <div className="flex flex-col w-full cursor-pointer" ref={ optionRef }>
                    <OptionButton text={ 'Show Details' } onClick={ () => setShowDetails(c => true) } />
                    <OptionButton text={ 'Show Photos' } onClick={ () => onPreviewPhoto(c => true) } />
                    <OptionButton text={ 'Confirm Order' } onClick={ handleConfirm } disabled={ order.is_confirmed === 1 ? true : false } />
                    { parseInt(order.status_id) !== 2 && <OptionButton text={ `Reject` } onClick={ hadnleChangeStatus } /> }
                    <OptionButton text={ `${order.driver_id ? 'Change' : 'Assign'} Driver` } onClick={ handleAssign } />
                    <OptionButton text={ 'Delete Order' } disabled={ true } />
                </div>
            </div>
            : null
    )
}

export default OrderOptions