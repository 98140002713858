import Widgets from "../../Widgets"

const Footer = () => {
    return (
        <div id="footer" className="bottom-0 flex w-full bg-primary p-3 justify-center text-sm text-color5">
            <div
                className="text-sm">
                Developed by <span className="text-plant italic">Rapid Development</span>
            </div>
            <Widgets />
        </div>
    )
}

export default Footer