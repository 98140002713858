import { useState } from "react";
import { Input } from "../conponents/Inputs";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";

export default function Login() {
    const navigate = useNavigate()

    const [user, setUser] = useState('')
    const [pass, setPass] = useState('')

    const [error, setError] = useState('')
    const [required, setRequired] = useState({ user: false, pass: false })

    const { fetchData } = useFetch();

    const handleSubmit = async () => {
        setError('')
        if (!user) {
            setRequired({ ...required, user: true })
            setError('Username are required')
            return
        }

        if (!pass) {
            setRequired({ ...required, pass: true })
            setError('Password are required')
            return
        }

        try {
            const response = await fetchData("POST", "user/check", { user, pass })
            if (response instanceof Error) {
                setError('500 - Internal Error')
            } else {
                if (response.status === 200) {
                    const { accessToken } = response.data
                    await sessionStorage.setItem('access', accessToken)
                    navigate('/')
                } else if (response.status >= 201 && response.status < 300) {
                    setError(response.data.err)
                }
            }
        } catch (err) {
            setError(err.message)
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit()
        }
    };

    return (
        <div className="min-h-screen flex flex-col w-full bg-primary justify-center items-center px-0.5">
            <div className="flex flex-col bg-secondary md:min-w-[32rem] min-w-full p-5 rounded shadow">
                <div className="flex p-5">
                    <span className="text-black dark:text-color5 font-bold w-full text-center text-xl">Login to <span className="text-plant">Rapid</span></span>
                </div>
                <div className="flex flex-col md:flex-row p-5">
                    <Input
                        label={ 'Username' }
                        id='username'
                        value={ user }
                        onChange={ (e) => setUser(e.target.value) } onKeyDown={ handleKeyDown } />
                    <Input
                        type='password'
                        label={ 'Password' }
                        id='password'
                        value={ pass }
                        onChange={ (e) => setPass(e.target.value) } onKeyDown={ handleKeyDown } />
                </div>
                <div className="flex flex-col justify-center items-center p-5">
                    <span className="text-sm text-red italic">{ error }</span>
                    <button
                        className="w-full bg-color4 p-2 font-bold outline-none"
                        onClick={ handleSubmit }>
                        Login
                    </button>
                </div>
            </div>
        </div>
    )
}