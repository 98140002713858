import { useEffect, useState } from "react"
import Order from "../Orders/Order"
import { useFetch } from "../../hooks/useFetch"
import HttpStatus from "../../utils/HttpStatus"
import SocketClient from "../SocketClient"
import MainLayout from "../Layout"
import { Container, ContainerHeader } from "../Containers"

const OrdersPending = () => {
    const { loading, fetchData } = useFetch()
    const [pendingOrders, setPendingOrders] = useState([])

    const getOrders = async () => {
        try {
            const response = await fetchData('POST', 'order/get')
            if (response.status === HttpStatus.OK)
                setPendingOrders(response.data.result)
            else
                setPendingOrders([])

        } catch (err) { }
    }

    const refreshOrder = async (updateOrder) => {
        if (updateOrder) {
            setPendingOrders(prevArray => {
                // Remove object with name "Complete"
                const filteredArray = prevArray.filter(obj => obj.name !== "Complete");

                // Check if the object exists in the array
                const exists = filteredArray.some(obj => obj.id === updateOrder.id);
                if (exists) {
                    // Update the object in the array
                    return filteredArray.map(obj => obj.id === updateOrder.id ? updateOrder : obj);
                } else {
                    // Add the new object to the array
                    return [...filteredArray, updateOrder];
                }
            });
        }

    }

    useEffect(() => {
        getOrders()
        // eslint-disable-next-line
    }, [])

    return <>
        <MainLayout>
            <Container>
                <ContainerHeader showBack={ true } page={ 'Current Orders' } />
                <SocketClient refreshOrder={ refreshOrder } />
                <div className="w-full h-full flex flex-wrap overflow-auto">
                    {
                        pendingOrders && pendingOrders.length > 0
                            ? pendingOrders?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map((x, index) => {
                                return <Order key={ index } row={ x } />
                            })
                            : !loading && <span className="w-full h-fit flex items-center justify-center italic text-sm">No current orders...</span>
                    }
                </div>
            </Container>
        </MainLayout>
    </>
}

export default OrdersPending