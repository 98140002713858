import { useEffect } from "react"
import useData from "../../hooks/useData"
import LeftSide from './LeftSide'
import Header from "./Header"
import Footer from './Footer'

const MainLayout = ({ children, active, className }) => {
    const { hideSplash } = useData()

    useEffect(() => {
        window.scrollTo(0, 0)
        hideSplash()
        // eslint-disable-next-line
    }, [])

    return (
        <div className={ "relative flex flex-col w-full min-w-screen h-screen max-w-screen z-[1] " + className }>
            <Header active={ active } />
            <div className="flex w-full h-full overflow-auto relative bg-black z-1">
                <LeftSide />
                <div className="flex items-center justify-center w-full h-full py-2 px-0 md:px-2 overflow-y-auto z-1">
                    { children }
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default MainLayout