import { useEffect, useRef, useState } from "react"
import Widget from "./Widget"
import { PickTimeIco } from "../../assets/Icons"
import { useOutsideFinder } from "../../hooks/useOutsideFinder"
import useData from "../../hooks/useData"
import HttpStatus from "../../utils/HttpStatus"


const Widgets = () => {
    const widgetRef = useRef()
    const { actionOrder } = useData()
    const [show, setShow] = useState(false)
    const [pickTime, setPickTime] = useState({})

    useOutsideFinder(() => setShow(false), widgetRef)

    const getPickTime = async () => {
        const response = await actionOrder('PICK_TIME')
        if (response.status !== 200) {
        } else {
            if (response.status === HttpStatus.OK) {
                if (response.data.length > 0) {
                    setPickTime({
                        icon: <PickTimeIco />,
                        title: 'Top 3 PickTime',
                        text: '',
                        data: response.data
                    })
                }
            }
        }
    }

    useEffect(() => {
        getPickTime()
        // eslint-disable-next-line
    }, [])

    return (
        <div className={ `absolute w-[20rem] right-0 bottom-0  ${show ? 'h-screen' : ''} max-h-screen z-[200] text-white-gray` } ref={ widgetRef }>
            <div className="flex flex-col w-full h-full px-1 relative">
                { show && <div className="flex-grow flex w-full h-full bg-gray-light p-2 overflow-auto">
                    <Widget data={ pickTime }>
                        <div className="w-full px-2 overflow-auto">
                            <table className="w-full">
                                <thead>
                                    <tr className="sticky top-0 bg-primary border-b border-white-gray">
                                        <th className="text-left">Hour</th>
                                        <th className="text-right">Orders</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        pickTime.data
                                            ? pickTime.data?.map((x, index) => {
                                                return <tr key={ index } className="border-b border-gray-light text-white-gray">
                                                    <td className="text-left">
                                                        {
                                                            x.hour === 0
                                                                ? '12 AM'
                                                                : x.hour > 12 ? `${x.hour - 12} PM`
                                                                    : `${x.hour} AM`
                                                        }
                                                    </td>
                                                    <td className="text-right">{ x.ordered }</td>
                                                </tr>
                                            })
                                            : null
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Widget>
                </div> }
                { !show && <div className="flex justify-center items-center w-fit cursor-pointer p-1 ">
                    <span className="absolute bottom-5 right-5 border border-gray-light bg-primary rounded-sm p-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={ 1.5 } stroke="currentColor" className="w-6 h-6" onMouseEnter={ () => setShow(true) }>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                        </svg>
                    </span>
                </div> }
            </div>
        </div>
    )
}

export default Widgets