import { useEffect } from "react";
import sound from '../../assets/notification1.mp3'

const PlaySoundOnShow = (props) => {
    const playSound = () => {
        const audio = new Audio(sound);
        audio.play();
    };

    useEffect(() => {
        props.play && playSound()
    }, props.play)

    return
};

export default PlaySoundOnShow;
