import { useState } from 'react'
import { BsSearch } from 'react-icons/bs'
import { MdClear } from 'react-icons/md'

const SearchButton = ({ id, placeholder, value, onChange, onClear, width = 'max-w-2', alwaysShow = false }) => {
    const [showSearch, setShowSearch] = useState(false)
    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            if (!alwaysShow) {
                onClear()
                const input = document.querySelector(`#${id}`)
                input.blur()
                setShowSearch(false)
            }
        }
    }

    window.addEventListener('keydown', (event) => {
        if (event.ctrlKey && (event.key === 's' || event.key === 'S')) {
            event.preventDefault()
            setShowSearch(true)
            const input = document.querySelector('#searchOrder')
            input.focus()
        }
    })

    return <div className={`${width} relative my-2 md:my-0 px-2`}>
        <input id={id} className={`${alwaysShow ? 'block' : ((showSearch) ? 'block' : 'hidden')} w-full h-full p-2 rounded outline-none bg-transparent border border-gray-light placeholder:text-sm placeholder:italic placeholder:text-gray-light px-10`} placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} onKeyDown={handleKeyDown} />
        <BsSearch className={`absolute top-1/2 -translate-y-1/2 ${alwaysShow ? 'left-4' : (showSearch ? 'left-4' : 'right-4')}`} onMouseEnter={() => setShowSearch(true)} />
        {value.length > 0 && <MdClear className='absolute top-1/2 -translate-y-1/2 right-4 cursor-pointer' onClick={onClear} />}
    </div>
}

export default SearchButton