import { AiOutlineClose } from "react-icons/ai"
import { HiShoppingBag } from "react-icons/hi"
import TitleSpan from "../Span/TitleSpan"
import TextSpan from "../Span/TextSpan"
import useData from "../../hooks/useData"
import { useLayoutEffect, useState } from "react"
import HttpStatus from "../../utils/HttpStatus"
import NoData from "../Global/NoData"
import ShowMore from "./ShowMore"
import RightModal from "../Global/RightModal"
import formatNumber from "../../utils/FormatNumber"


const OrderDetails = ({ order, setShowDetails }) => {
    const { actionOrder } = useData();
    const [details, setDetails] = useState([]);
    const [orderBill, setOrderBill] = useState({});
    const [pointerX, setPointerX] = useState('');
    const [pointerY, setPointerY] = useState('');
    const [showMore, setShowMore] = useState(false);
    const [showMoreData, setShowMoreData] = useState({});

    window.addEventListener("mousemove", (event) => {
        setPointerX(`${event.clientX}px`);
        setPointerY(`${event.clientY}px`);
    });

    const getDetails = async () => {
        try {
            const response = await actionOrder('ORDER_DETAILS', { order_id: order.id })
            if (response.status !== 200) {
            } else {
                if (response.status === HttpStatus.OK) {
                    if (response.data.length > 0) {
                        setDetails(response.data);
                        setOrderBill(response.orderBill)
                    }
                }
            }
        } catch (err) {
        }
    }


    const handleShowMore = async (e, data) => {
        await setPointerX(e.clientX)
        await setPointerY(e.clientY)
        setShowMoreData(c => { return data })
        setShowMore(true)
    }


    const handleHideMore = () => {
        setShowMore(false)
    }

    useLayoutEffect(() => {
        getDetails()
        // eslint-disable-next-line
    }, [])


    return <>
        <RightModal icon1={ <HiShoppingBag size={ 25 } /> } icon2={ <AiOutlineClose size={ 20 } onClick={ setShowDetails } /> } onClick={ setShowDetails } order={ order } >
            <div className="flex flex-col mt-2 pb-2 border-b border-gray-light">
                <TitleSpan text={ 'Date' }>
                    <TextSpan text={ order.created_at || '' } />
                </TitleSpan>
                <TitleSpan text={ 'Customer no.' }>
                    <TextSpan text={ order.customer_number || '' } />
                </TitleSpan>
                <TitleSpan text={ 'Driver' }>
                    <TextSpan text={ `${order.first_name || ''} ${order.last_name || ''}` } />
                </TitleSpan>
                {/* <TitleSpan text={ 'Delivery charge' }>
                    <TextSpan text={ `LBP ${order.service_charge.toFixed(2).toLocaleString() || 'NaN'}` } />
                </TitleSpan> */}
                <TitleSpan text={ 'To Address' }>
                    <TextSpan text={ `${order.to_address || ''}` } />
                </TitleSpan>
                <TitleSpan text={ 'From/to Location' }>
                    <TextSpan text={ `${order.from_price_list || ''}/${order.to_price_list || ''}` } />
                </TitleSpan>
            </div>
            <div className="flex items-center p-1 py-2">
                <span className="text-sm italic px-1">Note:</span>
                <span className="text-sm italic text-white-gray">{ order.description ? order.description : 'empty.' }</span>
            </div>
            <div className="flex-grow flex flex-col h-full overflow-auto">
                <table className="w-full">
                    <thead>
                        <tr className="border-b border-white-gray text-green">
                            <th className="text-left py-2">Product</th>
                            {/* <th className="text-left py-2">Description</th> */ }
                            <th className="text-right py-2">Qty</th>
                            <th className="text-right py-2">Unit Price</th>
                            <th className="text-right py-2">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            details && details.length > 0
                                ? details.map((x, index) => {
                                    return <tr key={ index } className={ `border-b border-gray-light ${index % 2 === 0 ? 'text-white-gray' : 'text-white'}` } >
                                        <td className="text-left py-2 w-[60%]" onClick={ (e) => handleShowMore(e, x) } onMouseLeave={ handleHideMore }>
                                            <div className="flex flex-col relative">
                                                <>{ x.product_name || x.description || '' }</>
                                                <span className="pl-3 text-white-gray flex flex-col">{ x.addons?.split('-').map((x, index) => {
                                                    return x && <span key={ index }>- { x }</span>
                                                }) }</span>
                                                { x.instruction && <span className="pl-3 text-red">{ x.instruction }</span> }
                                            </div>
                                        </td>
                                        {/* <td className="text-right py-2">{x.description}</td> */ }
                                        <td className="text-right py-2">{ x.product_id > 0 ? x.quantity.toFixed(2) : '' }</td>
                                        <td className="text-right py-2">{ x.product_id > 0 ? x.price.toFixed(2).toLocaleString() : '' }</td>
                                        <td className="text-right py-2">{ x.product_id > 0 ? (x.price * x.quantity).toFixed(2).toLocaleString() : '' }</td>
                                    </tr>
                                })
                                : null
                        }
                    </tbody>
                </table>
                { details && details.length === 0 ? <NoData /> : null }

                <ShowMore show={ showMore } t={ pointerY } l={ pointerX } details={ showMoreData } />
            </div>
            <footer className="flex flex-col mt-2 pb-2 border-b border-gray-light">
                <TitleSpan text={ 'Subtotal' }>
                    <TextSpan text={ `USD ${formatNumber(orderBill?.subtotal || 0)}` } />
                </TitleSpan>
                <TitleSpan text={ 'Delivery fee' }>
                    <TextSpan text={ `USD ${formatNumber(orderBill?.delivery_charge || 0)}` } />
                </TitleSpan>
                <TitleSpan text={ 'Service Charge' }>
                    <TextSpan text={ `USD ${formatNumber(orderBill?.service_charge || 0)}` } />
                </TitleSpan>
                <TitleSpan text={ 'Total' }>
                    <TextSpan text={ `USD ${formatNumber(orderBill?.total_usd || 0)}` } />
                    <span className="px-1">LBP { formatNumber(orderBill?.total_lbp, 0) || 0 } </span>
                </TitleSpan>
            </footer>
        </RightModal>
    </>
}

export default OrderDetails