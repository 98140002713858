import Driver from "./Driver"
import { FaMotorcycle } from 'react-icons/fa'
import { AiOutlineClose } from 'react-icons/ai'
import { useState, useLayoutEffect } from "react"
import useData from "../../hooks/useData"
import SearchButton from "../Button/SearchButton"
import NoData from '../Global/NoData'


const Drivers = ({ setShowAssign, orderId }) => {
    const { actionDriver } = useData()
    const [driverList, setDriverList] = useState([])
    const [searchKey, setSearchKey] = useState('')

    const filteredDriver = driverList?.filter(driver => {
        const fullName = driver.first_name + ' ' + driver.last_name
        return fullName.toLowerCase().includes(searchKey.toLowerCase())
    })

    const getDrivers = async () => {
        try {
            const response = await actionDriver('GET')
            setDriverList(response.data)
        } catch (err) {
        }
    }
    useLayoutEffect(() => {
        getDrivers()
        const searchInput = document.querySelector('#searchDriver')
        searchInput.focus()
        // eslint-disable-next-line
    }, [])

    return (
        <div className="fixed inset-0 w-screen h-screen z-[150]">
            <div className="flex items-center justify-center w-full h-full">
                <div className="flex flex-col justify-center w-full md:w-[30%] bg-primary p-5 border border-gray-light rounded-md z-[100]">
                    <header className="flex flex-row items-center p-2 fill-white-gray text-white-gray border-b border-gray-light cursor-pointer">
                        <FaMotorcycle size={ 25 } />
                        <h1 className="flex-grow font-bold px-2">Choose a driver for Order <span className="text-plant">#{ orderId }</span></h1>
                        <AiOutlineClose size={ 20 } onClick={ setShowAssign } />
                    </header>
                    <div className="p-2 pt-5">
                        <div className="">
                            <SearchButton id={ 'searchDriver' } value={ searchKey } onChange={ (e) => setSearchKey(e) } alwaysShow={ true } onClear={ () => setSearchKey('') } placeholder={ 'Search a driver' } />
                        </div>
                        <div className="max-h-36 mt-2 overflow-auto">
                            {
                                filteredDriver && filteredDriver.length > 0
                                    ? filteredDriver.map((x, index) => {
                                        return <Driver key={ index } data={ x } orderId={ orderId } setShowAssign={ setShowAssign } />
                                    })
                                    : <NoData />
                            }
                        </div>
                    </div>
                </div>
                <div className="fixed inset-0 opacity-80 bg-primary z-[99]" onClick={ () => setShowAssign(false) }></div>
            </div>
        </div>
    )
}

export default Drivers