import { useNavigate } from "react-router-dom"
import useData from "../../../hooks/useData"
import { useEffect, useRef, useState } from "react"
import { useOutsideFinder } from "../../../hooks/useOutsideFinder"
import { CloseIco, MenuIco, ProfileIco } from "../../../assets/Icons"
import ChangePassword from "../../ChangePassword"
import { isMobile } from "react-device-detect"
import MenuItem from "../LeftSide/MenuItem"
import logo from '../../../assets/img/Rapid_4_001-removebg-preview(1).png'
import { jwtDecode } from "jwt-decode"
import constant from "../../../utils/constant"


const Header = ({ active }) => {
    const navigate = useNavigate()
    const { showMenu, setShowMenu } = useData()
    const [showPassModal, setShowPassModal] = useState(false)
    const [showOption, setShowOption] = useState(false)
    const [user, setUser] = useState('')
    const profileRef = useRef()

    useOutsideFinder(() => setShowOption(false), profileRef)

    useEffect(() => {
        const getUser = async () => {
            try {
                const response = await jwtDecode(constant.TOKEN) || { name: 'User' }
                setUser(c => response.name || '')
            } catch (err) {
            }
        }

        getUser()
        return () => {
            setUser('')
        }
    }, [])

    return (
        <div className="flex items-center bg-primary h-[6rem] z-[1]">
            <div className="flex items-center">
                {
                    isMobile
                        ? showMenu
                            ? <CloseIco className={ 'text-color5 cursor-pointer' } onClick={ () => setShowMenu(!showMenu) } />
                            : <MenuIco className={ 'text-color5 cursor-pointer' } onClick={ () => setShowMenu(!showMenu) } />
                        : <MenuIco className={ 'text-color5 cursor-pointer' } onClick={ () => setShowMenu(!showMenu) } />
                }

            </div>
            <div className="flex-grow">
                <img src={ logo } alt={ 'logo' } className="hidden xs:block w-48" />
            </div>
            <div className="relative z-1">
                <MenuItem className={ (active === 'profile' ? 'text-plant' : '') } isIco={ true } onClick={ () => setShowOption(true) }>
                    <ProfileIco className={ 'w-10 h-10 rounded-full bg-secondary overflow-hidden' } />
                </MenuItem>
                {
                    showOption && <div className="absolute z-[5] right-0 bg-primary p-1" ref={ profileRef }>
                        <div className="flex whitespace-nowrap text-white-gray p-1 hover:bg-plant" onClick={ () => navigate(`/Store/${user}/profile`) }>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={ 1.5 } stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                            </svg>
                            <label className="px-2">Profile</label></div>
                        <div className="flex whitespace-nowrap text-white-gray p-1 hover:bg-plant" onClick={ () => setShowPassModal(!showPassModal) }>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={ 1.5 } stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                            </svg>
                            <label className="px-2">Change Password</label></div>
                        <div className="flex whitespace-nowrap text-white-gray p-1 hover:bg-plant" onClick={ () => { sessionStorage.clear(); localStorage.clear(); navigate('/Login') } }>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={ 1.5 } stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                            </svg>
                            <label className="px-2">Logout</label>
                        </div>
                        <ChangePassword show={ showPassModal } setShow={ (e) => setShowPassModal(false) } />
                    </div>
                }
            </div>
        </div>
    )
}

export default Header