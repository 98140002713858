import { useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import useData from "../../../hooks/useData"
import LeftMenu from "./MenuItem"
import { GroupIco, HomeIco, NewIco, ProductIco } from "../../../assets/Icons"
import { useOutsideFinder } from "../../../hooks/useOutsideFinder"

const LeftSideMobile = ({ user, active }) => {
    const navigate = useNavigate()
    const [menuExpand, setMenuExpand] = useState(false)
    const menuRef = useRef()
    const { showMenu, setShowMenu } = useData()

    const handleExpand = (e) => { setMenuExpand(!menuExpand) }

    const navigateTo = (url) => {
        setShowMenu(false)
        setMenuExpand(false)
        navigate(url)
    }

    useOutsideFinder(() => setMenuExpand(false), menuRef)
    useOutsideFinder(() => setShowMenu(false), menuRef)
    return (
        showMenu
            ? <div className={"absolute flex flex-col bg-secondary w-full z-[50] "} ref={menuRef}>
                <LeftMenu
                    className={(active === 'home' ? 'bg-plant text-primary' : '')}
                    onClick={() => navigateTo(`/`)}
                    ico={<HomeIco />}>
                    Home
                </LeftMenu>
                <LeftMenu
                    className={(active === 'menu' ? 'bg-plant text-primary' : '')}
                    onClick={handleExpand}
                    ico={<ProductIco />}>
                    Order
                </LeftMenu>
                <div ref={menuRef}>
                    {
                        menuExpand
                            ? <div className="bg-secondary p-2">
                                <LeftMenu ico={<NewIco />} className={'text-sm'} onClick={() => navigateTo(`/`)}>New</LeftMenu>
                                <LeftMenu ico={<GroupIco />} className={'text-sm'} onClick={() => navigateTo(`/order_list`)}>List</LeftMenu>
                                <LeftMenu ico={<GroupIco />} className={'text-sm'} onClick={() => navigateTo(`/`)}>Price List</LeftMenu>
                                {/* <LeftMenu ico={<ListIco />} className={'text-sm'} onClick={() => navigateTo(`/Store/${user}/group`)}>Groups</LeftMenu> */}
                            </div>
                            : <></>
                    }
                </div>
                {/* <LeftMenu
                    className={(active === 'report' ? 'bg-plant text-primary' : '')}
                    onClick={() => { navigateTo(`/`) }}
                    ico={<DocumentIco />}>
                    Report
                </LeftMenu>
                <LeftMenu
                    className={(active === 'order' ? 'bg-plant text-primary' : '')}
                    onClick={() => navigateTo(`/`)}
                    ico={<DocumentIco />}>
                    Order
                </LeftMenu> */}
            </div >
            : <></>

    )
}

export default LeftSideMobile