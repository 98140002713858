const Tabs = ({ filteredKey = -1, setFilteredKey }) => {
    const optionStyle = `flex h-fit w-fit items-center cursor-pointer p-2 rounded`

    const handleFilter = (e) => {
        setFilteredKey(e.target.id)
    }

    return (
        <div className="flex-grow w-fit h-fit flex flex-row items-center text-sm p-5 md:p-2 overflow-x-auto">
            <button id="-1" className={ `${optionStyle} ${filteredKey < 0 ? 'bg-gray-light' : ''}` } onClick={ handleFilter }>
                <div className="w-4 h-4 bg-color3 rounded-full"></div>
                <span className="px-1">All</span>
            </button>
            <button id="1" className={ `${optionStyle} ${filteredKey === 1 ? 'bg-gray-light' : ''}` } onClick={ handleFilter }>
                <div id="1" className="w-4 h-4 bg-color2 rounded-full"></div>
                <span id="1" className="px-1">Pending</span>
            </button>
            <button id="2" className={ `${optionStyle} ${filteredKey === 2 ? 'bg-gray-light' : ''}` } onClick={ handleFilter }>
                <div id="2" className="w-4 h-4 bg-green rounded-full"></div>
                <span id="2" className="px-1">Complete</span>
            </button>
            <button id="3" className={ `${optionStyle} ${filteredKey === 3 ? 'bg-gray-light' : ''}` } onClick={ handleFilter }>
                <div id="3" className="w-4 h-4 bg-red rounded-full"></div>
                <span id="3" className="px-1">Reject</span>
            </button>
            <button id="4" className={ `${optionStyle} ${filteredKey === 4 ? 'bg-gray-light' : ''}` } onClick={ handleFilter }>
                <div id="4" className="w-4 h-4 bg-color4 rounded-full"></div>
                <span id="4" className="px-1">Confirmed</span>
            </button>
            <button id="5" className={ `${optionStyle} ${filteredKey === 5 ? 'bg-gray-light' : ''}` } onClick={ handleFilter }>
                <div id="5" className="w-4 h-4 bg-color5 rounded-full"></div>
                <span id="5" className="px-1">Getting Order</span>
            </button>
            <button id="6" className={ `${optionStyle} ${filteredKey === 6 ? 'bg-gray-light' : ''}` } onClick={ handleFilter }>
                <div id="6" className="w-4 h-4 bg-color6 rounded-full"></div>
                <span id="6" className="px-1">On The Way</span>
            </button>
            <button id="7" className={ `${optionStyle} ${filteredKey === 7 ? 'bg-gray-light' : ''}` } onClick={ handleFilter }>
                <div id="7" className="w-4 h-4 bg-color7 rounded-full"></div>
                <span id="7" className="px-1">Order Picked</span>
            </button>
            <button id="8" className={ `${optionStyle} ${filteredKey === 8 ? 'bg-gray-light' : ''}` } onClick={ handleFilter }>
                <div id="8" className="w-4 h-4 bg-pink-500 rounded-full"></div>
                <span id="8" className="px-1">Scheduled</span>
            </button>
            <button id="9" className={ `${optionStyle} ${filteredKey === 9 ? 'bg-gray-light' : ''}` } onClick={ handleFilter }>
                <div id="9" className="w-4 h-4 bg-orange-400 rounded-full"></div>
                <span id="9" className="px-1">Canceled</span>
            </button>
        </div>
    )
}

export default Tabs