import { useEffect, useState } from "react"
import RightModal from "../Global/RightModal"
import { AiOutlineClose } from "react-icons/ai"
import { HiShoppingBag } from "react-icons/hi"
import { useFetch } from "../../hooks/useFetch"
import { set } from "date-fns"

const OrderPhotos = ({ show, order, onClick }) => {
    const { fetchData, loading } = useFetch()
    const [photoList, setPhotoList] = useState([])

    const getPhotos = async () => {
        const response = await fetchData('POST', 'order/getPhotos', { order_id: order.id })
        setPhotoList(response.data)
    }

    useEffect(() => {
        if (show)
            getPhotos()
        // eslint-disable-next-line
    }, [show])

    return show && <>
        <RightModal order={ order } icon1={ null } icon2={ <AiOutlineClose onClick={ onClick } size={ 30 } /> } onClick={ onClick } width={ 'md:max-w-[300px]' }>
            <div className="flex flex-col justify-center overflow-auto gap-2">
                { photoList?.map((photo, index) => {
                    return <div key={ index } className="flex items-center justify-ceter w-full h-48 shadow-lg">
                        <img src={ photo.image_url } alt={ photo.user_id } className="w-full h-full" />
                    </div>
                })
                }
            </div>
        </RightModal>
    </>
}

export default OrderPhotos