import { useEffect, useRef, useState } from "react"
import Order from "./Order"
import Tabs from "./Tabs"
import ClearFilterButton from "../Button/ClearFIlterButton"
import SearchButton from "../Button/SearchButton"
import MiniLoader from "../Global/MiniLoader"
import NewOrderLoader from "../Global/NewOrderLoader"
import { useFetch } from "../../hooks/useFetch"
import HttpStatus from "../../utils/HttpStatus"

const Orders = () => {
    const scrollDivRef = useRef()
    const [filteredKey, setFilteredKey] = useState(-1)
    const [searchKey, setSearchKey] = useState('')
    const { fetchData, loading } = useFetch()
    const [showComplete, setShowComplete] = useState(false)
    const [orderList, setOrderList] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const recordPerPage = 50

    const searchedData = searchKey ? orderList?.filter((item) => {
        const fullName = `${item.first_name} ${item.last_name}`
        return item.customer_number.toString().toLowerCase().includes(searchKey.toLowerCase()) ||
            item.id.toString().toLowerCase().includes(searchKey.toLowerCase()) ||
            fullName.toLowerCase().includes(searchKey.toLowerCase())
    }) : orderList

    const showFilter = filteredKey > 0 ? true : false

    const getOrders = async () => {
        try {
            const response = await fetchData('POST', 'order/get', { filteredKey, currentPage, recordPerPage })
            if (response.status === HttpStatus.OK) {
                setOrderList([...orderList, ...response.data.result])
                setCurrentPage(prev => prev + 1)
                if (orderList?.length >= response.data.totalOrders)
                    setHasMore(false)
                else
                    setHasMore(true)
            }
            else
                setOrderList([])

        } catch (err) {
        }
    }

    const handleScroll = () => {
        if (hasMore) {
            const scrollDiv = scrollDivRef.current;
            if (scrollDiv.scrollTop + scrollDiv.clientHeight >= scrollDiv.scrollHeight)
                getOrders()
        }
    };

    const handleFilter = (e) => {
        setOrderList([])
        setCurrentPage(1)
        setFilteredKey(e)
    }

    useEffect(() => {
        getOrders()
        // eslint-disable-next-line
    }, [filteredKey])

    return (
        <div className="flex flex-col w-full h-full overflow-auto relative">
            <MiniLoader show={ loading } />
            <div className="flex flex-col md:flex-row whitespace-nowrap">
                <Tabs filteredKey={ filteredKey } setFilteredKey={ handleFilter } />
                { showFilter && <ClearFilterButton setFilteredKey={ (e) => setFilteredKey(e) } /> }
                <SearchButton id={ 'searchOrder' } width="w-full md:w-[40%] mt-7" placeholder={ 'press ctl + s to search order, phone, driver' } value={ searchKey } onChange={ (e) => setSearchKey(e) } onClear={ () => setSearchKey('') } />
            </div>
            <div className="flex items-center justify-end px-2">
                <input id="showAll" type="checkbox" className="cursor-pointer p-2" value={ showComplete } onChange={ (e) => setShowComplete(e.target.checked) } />
                <label htmlFor="showAll" className="cursor-pointer p-2">Show All</label>
            </div>
            <NewOrderLoader />
            <div className="w-full h-full flex flex-wrap overflow-auto" ref={ scrollDivRef } onScroll={ handleScroll }>
                {
                    searchedData && searchedData.length > 0
                        ? searchedData?.map((x, index) => {
                            return <Order key={ index } row={ x } />
                        })
                        : !loading && <span className="w-full h-fit flex items-center justify-center italic text-sm">No data to show</span>
                }
            </div>
        </div>
    )
}

export default Orders