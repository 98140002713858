

const ShowMore = ({ show, t, l, details }) => {

    return show && <div style={{ top: t, left: l }} className={`fixed z-[199] rounded border border-transparent text-white`}>
        <div className="flex flex-col bg-secondary p-3 w-full max-w-[25rem] h-fit">
            <div className="flex flex-row p-1 w-fit">
                <span className="px-1 italic text-sm text-white-gray whitespace-nowrap">*Original Name:</span>
                <span className="px-1 italic text-sm">{details.originalName}</span>
            </div>
            <div className="flex flex-row p-1 w-fit">
                <span className="px-1 italic text-sm text-white-gray whitespace-nowrap">*Original Description:</span>
                <span className="px-1 italic text-sm">{details.originalDesc}</span>
            </div>
        </div>
    </div>
}

export default ShowMore