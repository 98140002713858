import { format, isValid, isWithinInterval, set, startOfDay, addHours } from 'date-fns'

export const convert24to12 = (time) => {
    let aDate = new Date()
    aDate.setHours(parseInt(time.split(':')[0]), parseInt(time.split(':')[1]))
    let convertedTime = aDate.toLocaleTimeString('en', { hour12: true, hour: '2-digit', minute: '2-digit' })

    return convertedTime
}

export const convert12to24 = (time) => {
    let timeValue = time
    let hour = timeValue.split(':')[0]
    let mins = timeValue.split(':')[1].split(' ')[0]
    let dayTime = timeValue.split(' ')[1]
    if (dayTime === 'PM' && hour !== '12') {
        hour = parseInt(hour) + 12
    }
    if (hour === '12' && dayTime === 'AM') hour = '00'

    let convertedTime = hour + ':' + mins

    return convertedTime
}

export const formatDateTime = (time, formatted) => {
    if (!isValid(new Date(time))) {
        return;
    }

    if (formatted) {
        return format(new Date(time), formatted);
    } else {
        return format(new Date(time), 'yyyy-MM-dd HH:mm');
    }
}

export const isBetween = (currentTime, start, end) => {
    if (!start || !end) {
        return null;
    }

    const now = new Date(currentTime);
    const openingTime = set(now, { hours: start.getHours(), minutes: start.getMinutes(), seconds: start.getSeconds() });
    let closingTime = set(now, { hours: end.getHours(), minutes: end.getMinutes(), seconds: end.getSeconds() });

    // Adjust closing time if it's before opening time (next day)
    if (closingTime < openingTime) {
        closingTime = addHours(closingTime, 24);
    }

    // Start of the current day
    const todayStart = startOfDay(now);

    // Create the interval for the store hours
    // const storeHours = { start: openingTime, end: closingTime };

    // Check if the current time is within the store's opening and closing hours
    const isStoreOpen = isWithinInterval(now, { start: todayStart, end: closingTime }) ||
        isWithinInterval(now, { start: openingTime, end: addHours(todayStart, 24) });

    if (isStoreOpen) {
    } else {
    }

    return isStoreOpen;
};
