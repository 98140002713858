import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
    const accessKey = sessionStorage.getItem('access')

    if (accessKey && accessKey !== 'undefined')
        return true;
    return false
}

const Authenticator = () => {
    const isAuth = useAuth();
    return isAuth ? <Outlet /> : <Navigate to="/Login" />;
}

export default Authenticator;