import { useRef, useState } from "react"
import { GroupIco, HomeIco, ListIco, NewIco, ProductIco } from "../../../assets/Icons"
import MenuItem from "./MenuItem"
import useData from "../../../hooks/useData"
import { useNavigate } from "react-router-dom"

const MenuDesk = ({ user, active }) => {
    const navigate = useNavigate()
    const { showMenu } = useData()
    const [menuExpand, setMenuExpand] = useState(false)
    const menuRef = useRef()

    const handleExpand = (e) => { setMenuExpand(!menuExpand) }

    const navigateTo = (url) => {
        navigate(url)
        setMenuExpand(false)
    }

    // useOutsideFinder(() => setMenuExpand(false), menuRef)
    return (
        showMenu
            ? <div className={ "lg:flex hidden flex-col bg-primary md:w-[10rem] h-full" }>
                <MenuItem
                    className={ (active === 'home' ? 'bg-plant text-primary' : '') }
                    onClick={ () => navigateTo(`/`) }
                    ico={ <HomeIco /> }>
                    Home
                </MenuItem>
                <MenuItem
                    className={ (active === 'menu' ? 'bg-plant text-primary' : '') }
                    onClick={ handleExpand }
                    ico={ <ProductIco /> }>
                    Order
                </MenuItem>
                <div ref={ menuRef }>
                    {
                        menuExpand
                            ? <div className="bg-secondary p-2">
                                <MenuItem ico={ <NewIco /> } className={ 'text-sm' } onClick={ () => navigateTo(`/`) }>New</MenuItem>
                                <MenuItem ico={ <GroupIco /> } className={ 'text-sm' } onClick={ () => navigateTo(`/order_list`) }>List</MenuItem>
                                {/* <MenuItem ico={ <ListIco /> } className={ 'text-sm' } onClick={ () => navigateTo(`/`) }>Price List</MenuItem> */ }
                                {/* <MenuItem ico={<ListIco />} className={'text-sm'} onClick={() => navigateTo(`/Store/${user}/group`)}>Groups</MenuItem> */ }
                            </div>
                            : <></>
                    }
                </div>
                <MenuItem
                    className={ (active === 'pending' ? 'bg-plant text-primary' : '') }
                    onClick={ () => navigateTo('/pending') }
                    ico={ <ProductIco /> }>
                    Current Orders
                </MenuItem>
            </div >
            : <></>
    )
}

export default MenuDesk