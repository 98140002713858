

const RightModal = ({ children, icon1, icon2, order, onClick, width }) => {
    return <div className="fixed inset-0 w-screen h-screen z-[250] fade-in">
        <div className="flex items-end justify-end w-full h-full">
            <div className={ `flex flex-col w-full ${width || 'md:w-[40%]'} h-full bg-primary p-5 border border-gray-light z-[100]` }>
                <header className="flex flex-row items-center p-2 fill-white-gray text-white-gray border-b border-gray-light cursor-pointer">
                    { icon1 }
                    <h1 className="flex-grow font-bold px-2">Order <span className="text-plant">#{ order?.id || null }</span></h1>
                    { icon2 }
                </header>
                <div className="flex flex-col p-2 pt-5 h-full">
                    { children }
                </div>
            </div>
        </div>
        <div className="fixed inset-0 opacity-80 bg-primary z-[99]" onClick={ onClick }></div>
    </div>
}

export default RightModal;