
const TitleSpan = ({ text, children, note, showNote }) => {
    return <div className="w-full h-10 flex items-center gap-2 shadow-md">
        <div className="flex-grow flex flex-col">
            <span className="text-plant text-xl px-1">{ text }</span>
            { showNote && <span className="italic text-white-gray text-sm">({ note })</span> }
        </div>
        <div className='flex flex-col items-end'>
            { children }
        </div>
    </div>
}

export default TitleSpan