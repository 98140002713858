import axios from "axios";
import constant from "../utils/constant";
import { useState } from "react";

// Set the global headers
axios.defaults.headers = {
    Authorization: `Bearer ${constant.TOKEN}`,
};

const useFetch = () => {
    const [loading, setLoading] = useState(true);

    async function fetchData(type, destination, data = null) {
        setLoading(true)
        try {
            const response = await axios({
                method: type,
                url: `/api/${destination}`,
                data: data,
            });
            // console.log(response)
            return response;
        } catch (error) {
            return error;
        } finally {
            setTimeout(() => setLoading(false), [1200])
        }
    }

    return {
        fetchData,
        loading, setLoading
    };
};

export { useFetch };
